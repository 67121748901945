import React from 'react'
import styled from 'styled-components'
import LoginBanner from '../components/common/LoginBanner'

const OuterContainer = styled.div`
@media only screen and (max-width: 768px) {
  min-height: 500px;
}
@media only screen and (min-width: 767px) {
  height: 500px;
}
`

// markup
const IndexPage = (props) => {
  const { location: { state = {} } = {} } = props
  const { emailAddress = '-' } = state === null ? {} : state
  return (
    <main className="main flex-fill" id="top">
      <div className="container-fluid">
        <div className="row min-vh-100 flex-center no-gutters">
          <div className="col-lg-8 col-xxl-5 py-3">
            <OuterContainer className="card overflow-hidden z-index-1">
              <div className="card-body p-0">
                <div className="row no-gutters h-100">
                  <LoginBanner showBackToLogin={true} />
                  <div className="col-md-7 d-flex flex-center">
                    <div className="px-4 px-md-5 d-flex flex-column flex-grow-1 my-5" style={{ minHeight: '300px' }}>
                      <h3 className="mb-3 font-weight-bold">Check your email</h3>

                      <p>An email has been sent to <b>{emailAddress}</b>. Click on the link to reset your password.</p>

                    </div>
                  </div>
                </div>
              </div>
            </OuterContainer>
          </div>
        </div>
      </div>
    </main>
  )
}

export default IndexPage
